!<template>
  <div class="tutorial">tutorial</div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.tutorial {
}
</style>